import React, { Children, useState } from "react";
import fadeIn from "../animations/FadeIn";

function SectionFadeIn({children}) {

    const [activeElementIndex, setActiveElementIndex] = useState(0)
    const arrayChildren = Children.toArray(children);
    
    const mod = (a) => {
      console.log(a)
      return Math.abs(a % arrayChildren.length)
    }


    return (
      <div className="gap-3 d-flex flex-row bg-blue shadow-black  p-5">
        <img className="align-self-center arrow" onClick={() => {setActiveElementIndex(activeElementIndex + 1)}} src="./ressources/arrow.svg" alt="arrow right" style={{ height: "17vh"}} />
        <div className= "gap-4 d-grid justify-content-start fade-in-element from-top slider">
          {arrayChildren[mod(activeElementIndex)]}
        </div>
        <img className="align-self-center arrow" onClick={() => {setActiveElementIndex(activeElementIndex - 1)}} src="./ressources/arrow.svg" alt="arrow right" style={{rotate: "180deg", height: "17vh"}} />
      </div>
    );
  }

export default SectionFadeIn
