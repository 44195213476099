import React from "react"

export default function Contact() {

    return <React.Fragment>

        <div className="d-flex justify-content-center p-5">
            <div className="bg-blue w-75 p-5 d-flex flex-row gap-5 p-5">
                <iframe className="rounded-5 col-7 align-self-center" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2890.927016248649!2d1.4654410774201707!3d43.566403858038484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12aebc394e6a9c79%3A0xc4cc0a937d98470c!2sComput&#39;Yourself!5e0!3m2!1sfr!2sfr!4v1724716393454!5m2!1sfr!2sfr" 
                width="600" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <div className="d-flex flex-column p-5 bg-dark-blue rounded-5 col-5 gap-5 align-self-center" style={{fontSize: "15px"}}>
                    <p>
                        <h2>Adresse Postale :</h2>
                        118 Route de Narbonne,<br/> Division de la Vie Etudiante - Bâtiment Administratif,<br/> Rez-de-chaussée porte 25, 31062 Toulouse Cedex 9 
                    </p>
                    <p>
                        <h2>Adresse du Local : </h2>
                        Le local de l'association est dans un batiment appele "Chez Paul Etudiant" ou la couleur verte y en ressort il est à côté des "salles S", en face du "143 Avenue de Rangueil, 31400 Toulouse" et dériere de la "Maison de la Recherche et de la Valorisation"
                    </p>
                    <p>
                        <h2>Horaires :</h2>
                        Le local de l'association est actuellement fermé jusqu'au 22 août
                        Ouvert toute la semaine et le week-end
                        Du dimanche au jeudi 10h-00h
                        Du vendredi au samedi 10h-03h
                        Ce sont des horaires à titre indicatif
                        Pour savoir si le local est bien ouvert rejoignez-nous sur discord 
                    </p>
                </div>
            </div>

            
        </div>
        

    </React.Fragment>
}