import '../css/caroussel.css';
import React from 'react';



function MovingImage( props) {
    return <div class="gallery">
		        <img src="/groupe1/AudioVisuel.jfif" alt="a lovely kiss in the night"/>
		        <img src="/groupe1/EspaceCollation.jfif" alt="a women inside a car"/>
		        <img src="/groupe1/PCEnLibreAcces.jfif" alt="a baby"/>
                <img src="/groupe1/VideoProjection.jpg" alt="a baby"/>
	        </div>
           
}


export default MovingImage