import React from "react";

export default function Magasin() {
    return <React.Fragment>
        <div className="h-100 d-block">
            <h1 style={{textAlign: "center"}}>Work in progress...</h1>
        </div>
        
        
    </React.Fragment>
}