import Icon from "./icon"

export default function Footer(props) {

    return <div className="row">
        <div className="bg-blue mt-3 p-4 d-flex justify-content-around gap-5 opacity-h-50">
            <div className="text-center d-flex flex-column  gap-2 ">
                <img src="./ressources/logo2024.png" style={{height: "8vh"}}/>

            </div>
            <div className="text-center d-flex flex-column  gap-2  " style={{fontSize: "10px"}}>
                <a  className="opacity-h-50" >
                    <img className="opacity-h-50"  style={{height: "30px"}} src="./reseaux/Carte.svg" alt="icone reseaux" />
                </a> 
                
                <p className="text-start">
                    118 Route de Narbonne,<br/> 
                    Division de la Vie Etudiante - Bâtiment Administratif,<br/> 
                    Rez-de-chaussée porte 25, 31062 Toulouse Cedex 9 
                </p>
                

            </div>
            <div className="text-center d-flex flex-column  gap-2 ">
                <a  className="opacity-h-50" >
                    <img className="opacity-h-50"  style={{height: "30px"}} src="./reseaux/Mail.svg" alt="icone reseaux" />
                </a> 
                
                contact@computyourself.fr
                

            </div>
            <div className="text-center d-flex flex-column  gap-2">
                Suis nous !

                <div className="d-flex gap-3 justify-content-center">
                    <Icon src="./reseaux/Facebook.svg" href="https://www.facebook.com/profile.php?id=100068555025599"/>
                    <Icon src="./reseaux/Instagram.svg" href="https://www.instagram.com/computyourself/"/>
                    <Icon src="./reseaux/Helloasso.svg" href="https://www.helloasso.com/associations/comput-yourself" />
                </div>
                <div className="d-flex gap-3 justify-content-center">
                    <Icon src="./reseaux/Twitch.svg" href="https://www.twitch.tv/computyourself" />
                    <Icon src="./reseaux/X.svg " href="https://x.com/ComputYourself" />
                    <Icon src="./reseaux/Youtube.svg" href="https://www.youtube.com/@computyourself4390" />
                </div>

            </div>
            
        </div>
        <div className="p-2 px-5 bg-dark-blue" style={{fontSize: "10px"}}>
            Copyright 2019 - Tous droits réservés. <a className="text-green">Mentions Légales</a>
        </div>
    </div>
}


