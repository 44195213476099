import React from "react"
import "../css/timeline.css"
import Poster from "../components/Poster"
import "../css/App.css"

export default function Evenements() {

    


  

    return <React.Fragment>

        <div className="w-100 justify-content-center d-flex">
            
            <div className="bg-blue shadow-black  gap-5 p-5 w-50 d-flex flex-column rounded-5 my-5" style={{overflow: "scroll", height: "75vh"}}>
                <h1 className="text-center">Prochain Evenements :</h1>
                <Poster image="./groupe2/Chandelan.png"/>
                <Poster image="./groupe2/Occilan.png"/>
                <Poster image="./groupe2/RentreFSI.png"/>
            </div>

        </div>
        



    </React.Fragment>
}