import '../css/App.css';

import MovingImage from '../components/caroussel';
import SectionFadeIn from "../components/SectionFadeIn";
import Thumbnails from "../components/Thumbnails";
import React from 'react';

export default function Accueil() {


    return <React.Fragment>
        
        <div id='first-view' className='containter-fluid '>
          
          <div className='row justify-content-between blur' style={{height: "95vh", "padding-bottom": "15vh", "padding-top": "15vh"}}>

            <div id='title' className='col-5  py-5 '>
              
              <div className='d-inline-flex align-items-end flex-column gap-5 shadow-black bg-blue px-5' style={{borderTopRightRadius: "150px", borderBottomRightRadius: "150px"}}>
                <img src='/ressources/Logo2024.png' id='logo' alt='logo comput yourself' className='w-75 '/>

                <p className='w-75 text-white'>
                  Passionné(e), Curieux(se), Adepte, Novice, ....
                  d'Informatique, de Nouvelles Technologies, de Jeux, ...<br></br>
                  Vous avez frappé à la bonne porte ! 
                </p>

              </div>
              
              
            </div>

            <div id="caroussel-container opacity-h-50" className='col-4 d-flex justify-content-start align-self-end '>
              <MovingImage/>
            </div>
            
          </div>

        </div>

        <div className='d-flex row flex-column gap-5  gap-4 mt-5 text-center text-white'>
            <h1> Ce que nous proposons </h1>



            <SectionFadeIn>

              <Thumbnails header="VR" image="/groupe1/RealiteVirtuelle.jfif">
                Téléportez-vous et explorez en toute liberté de nouveaux mondes virtuels, que ce soit en jouant, en développant des applications/jeux/... ou bien en regardant des vidéos.
                Bon ça ne sera pas une expérience comme SAO, mais on pourrait s'y croire.
              </Thumbnails>

              <Thumbnails header="Streaming" image="/groupe1/Streaming.jpg">
                Que vous ayez un problème logiciel ou matériel, l'association vous propose de vous aider à réparer votre ordinateur/tablette/raspberry pi/téléphone/... chaque mercredi après-midi à partir de 14h
                (Vous pouvez prendre contact par mail ou sur nos réseaux sociaux pour nous signaler votre problème)
              </Thumbnails>



              <Thumbnails header="Code" image="/groupe1/code.jpg">
                Que vous ayez un problème logiciel ou matériel, l'association vous propose de vous aider à réparer votre ordinateur/tablette/raspberry pi/téléphone/... chaque mercredi après-midi à partir de 14h
                (Vous pouvez prendre contact par mail ou sur nos réseaux sociaux pour nous signaler votre problème)
              </Thumbnails>

              <Thumbnails header="LAN" image="/groupe1/FullLan.png">
                Au cours de l'année, l'association organise des LANs se déroulant NON-STOP de 20h le vendredi à 20h le dimanche.
                Que vous soyez seul, en groupe, costumé, curieux, venez nous rejoindre lors de ces événements.
                Tout type de jeux est abordé, LoL, Factorio, 7DtoD, StartCraft II, OverWatch, ....., nos amis les consoles le sont aussi (Switch, Wii, ...), comme les jeux de plateau, de carte et de rôle.
                Une buvette est tenue au profit de l'association pour vous sustenter.
                Alors n'hésitez plus il y en a pour tous les goûts.
              </Thumbnails>

            </SectionFadeIn>

            


            <div className='bg-feuille d-flex justify-content-around flex-row p-5'
                style={{
                  height: "30vh"
                }}
            
            >
              


            </div>

            <div className='col-6 align-self-center d-flex flex-row justify-content-center gap-5 fade-in-element from-bottom '>
              <div className='p-0 d-flex flex-column align-self-center'>
                <h1 className='text-white '>REJOINS NOUS SUR </h1>
                <img alt="Logo Discord" src='/icons/discord-svgrepo-com.png' />
              </div>
              <iframe src="https://discord.com/widget?id=259654315193532416&theme=dark" width="600" height="400" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
            </div>

          </div>
        
        
    </React.Fragment>
}