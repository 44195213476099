import NavBarLink from "./NavBarLink";
import React from "react";

function NavBar({pageIndexUpdater}) {
    return (
      <div className="container-fluid d-flex justify-content-between align-items-center bg-blue  p-4">
        <div>
          <img src="/ressources/urobase.png" className="navbar-icon"/>
        </div>
        <div className="d-flex flex-row justify-content-center gap-3 align-items-center py-2">
          <NavBarLink text="Accueil" onClick={() => pageIndexUpdater(0)}/>
          <NavBarLink text="Evenements" onClick={() => pageIndexUpdater(1)}/>
          <NavBarLink text="Contact" onClick={() => pageIndexUpdater(2)}/>
          <NavBarLink text="Magasin" onClick={() => pageIndexUpdater(3)}/>
        </div>
        <div>
          <img src="/groupe1/ShortLogoUT3.jpg" className="navbar-icon"/>
        </div>
      </div>
    );
  }

  export default NavBar
