import React from "react";

function Thumbnails({children, header, image}) {

  const styles = {

    
    
    image: {
      height: "35vh",
      animation: "left-to-right-slide",
      "animation-duration": "1s"
    },

    text: {
      height: "40vh", 
      paddingRight:"50px", 
      paddingTop:"50px", 
      paddingBottom:"50px", 
      paddingLeft:"150px" , 
      marginLeft: "-100px", 
      zIndex: "-1",
      animation: "right-to-left-slide",
      "animation-duration": "1s",
      "font-size": "0.8vw"
      
      

    }

    
        
  }

  return (


    

      <div className=" gap-4 d-flex flex-sm-row flex-column p-0 justify-content-center p-0 slide g-start-5 ">

        <img  src={`${image}`} className="border rounded align-self-center shadow-black " style={styles.image}/>
        <div className="d-flex flex-column text-white text-center  col-6 border rounded shadow-black" style={styles.text}>
          <h3 className=" ">{header}</h3>
          <div className="h-100 d-flex flex-column justify-content-center text-start">
            {children}
          </div>
        </div>
      </div>

  );
}

  

export default Thumbnails

