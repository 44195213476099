import Accueil from "./pages/Accueil";
import Evenements from "./pages/Evenements";
import Contact from "./pages/Contact";
import Magasin from "./pages/Magasin";
import NavBar from "./components/NavBar";
import Footer from "./components/footer";
import { useState } from "react";
function App() {

  const [pageIndex, setPageIndex] = useState(0);

  const pages = [<Accueil/>, <Evenements/>, <Contact/>, <Magasin/>]
  
  return (
      <div className="App text-white">
        <NavBar pageIndexUpdater={setPageIndex} />
        {pages[pageIndex]}
        <Footer/>
      </div>
      
  );
}

export default App;
